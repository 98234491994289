/* About */
#menu-items:hover > .row > .menu-item:hover > .company1-logo {
    scale: 1;
    transform: rotate(-45deg);
    top: -30%;
    left: -15%;
}

#menu-items:hover > .row > .menu-item:hover > .company2-logo {
    scale: 1;
    transform: rotate(45deg);
    top: -30%;
    left: 100%;
}

#menu-items:hover > .row > .menu-item:hover > .company3-logo {
    scale: 1;
    transform: rotate(0deg);
    top: -50%;
}

#menu-items:hover > .row > .menu-item:hover > .company4-logo {
    scale: 1;
    transform: rotate(0deg);
    top: -50%;
}

/* Projects */
#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .node-logo {
    scale: 1;
    transform: rotate(-45deg);
    top: 95%;
    left: 100%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .sql-logo {
    scale: 1;
    transform: rotate(0);
    top: 115%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .java-logo {
    scale: 1;
    transform: rotate(0);
    top: 115%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .css-logo {
    scale: 1;
    transform: rotate(-135deg);
    top: 100%;
    left: -15%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .js-logo {
    scale: 1;
    transform: rotate(45deg);
    top: -30%;
    left: 100%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .python-logo {
    scale: 1;
    transform: rotate(0);
    top: -50%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .react-logo {
    scale: 1;
    transform: rotate(0);
    top: -50%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .html-logo {
    scale: 1;
    transform: rotate(-45deg);
    top: -30%;
    left: -15%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .linkedin-logo {
    scale: 1;
    transform: rotate(45deg);
    top: 80%;
    left: -15%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .coffee-logo {
    scale: 1;
    transform: rotate(0);
    top: 110%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .teams-logo {
    scale: 1;
    transform: rotate(0);
    top: 110%;
}

#menu-items:hover > .swapY > .row > .swapY:hover > .menu-item-2 > .gmail-logo {
    scale: 1;
    transform: rotate(-45deg);
    top: 80%;
    left: 102%;
}

/* My Photo */

.pictures-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    flex-direction: column;
}

.profile-background {
    height: clamp(10.5rem, 20rem, 30.5rem);
    width: clamp(10.5rem, 20rem, 30.5rem);
    background-color: rgb(35, 35, 35);
    /* background-color: #3a2963; */
    border-radius: 50%;
    position: relative;
}

.image-cropper {
    justify-content: center;
    align-items: center;
    margin: 1rem;

    border-radius: 50%;
    max-width: 80%;
    max-height: 80%;
    /* position: absolute; */
}

.lower-menu {
    display: flex;
    flex-direction: row;
}

.menu-button-wrapper {
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70vw;
}

.menu-button {
    margin-left: clamp(0.5rem, 1rem, 3rem);
    margin-right: clamp(0.5rem, 1rem, 3rem);
    height: clamp(3rem, 10vh, 6rem);
    width: clamp(10rem, 30vw, 20rem);
    text-decoration: none;
    color: rgb(220, 220, 220);
    /* border: solid #916FFF;
   */
    border: none;
    background-color: #7848c4;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 1.5em;
    border-radius: 0.3em;
    transition: background-color 0.2s;
}

.menu-button:hover {
    background-color: #916fff;
    border: solid #916fff;
}

.blurb-wrapper {
    height: 25vh;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    width: 30vw;
}

.blurb {
    height: clamp(3rem, 10vh, 6rem);
    width: clamp(10rem, 30vw, 20rem);
    border-radius: 0.5em;
    background-color: rgb(50, 50, 50);
    color: rgb(240, 240, 240);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-family: Lucida Sans Typewriter, Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
}

/* ABOUT */

#about {
    /* height: 100vh; */
}

.about-heading {
    height: 20vh;
    align-items: center;
    justify-content: center;
}

.about-greeting {
    margin-top: 1rem;
    margin-left: 3rem;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    height: clamp(3rem, 8vh, 6rem);
    width: clamp(10rem, 40vw, 40rem);
    border-radius: 0.5em;
    background-color: rgb(20, 20, 20);
    color: rgb(240, 240, 240);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lucida Sans Typewriter, Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
}

.about-title {
    padding-left: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: clamp(3rem, 4vw, 8rem);
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
}

.about-info {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.about-paragraph {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: fit-content;
    word-break: break-word;
    width: clamp(10rem, 80vw, 70rem);
    border-radius: 0.5em;
    background-color: rgb(20, 20, 20);
    color: rgb(240, 240, 240);
    font-family: Lucida Sans Typewriter, Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
    padding: 1rem;
}

.about-toggle {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.club-btn {
    height: 2.5rem;
    width: 12rem;
    clip-path: polygon(0 0, 92% 0, 100% 100%, 0 100%);
    background-color: #7848c4;
    border: #7848c4;
    border-bottom-left-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    color: rgb(220, 220, 220);
    font-size: 0.75rem;
    font-weight: bold;
    transition: 0.5s;
}

.club-btn:hover {
    cursor: pointer;
}

.all-btn {
    height: 2.5rem;
    width: 17rem;
    clip-path: polygon(0 0, 94.5% 0, 100% 100%, 5.5% 100%);
    background-color: #916fff;
    border: none;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.1rem;
    color: rgb(220, 220, 220);
    font-size: 0.75rem;
    font-weight: bold;
    transition: 0.5s;
}

.all-btn:hover {
    cursor: pointer;
}

.coop-btn {
    height: 2.5rem;
    width: 12rem;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 8% 100%);
    background-color: #7848c4;
    border: none;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-left-radius: 0.1rem;
    color: rgb(220, 220, 220);
    font-size: 0.75rem;
    font-weight: bold;
    transition: 0.5s;
}

.coop-btn:hover {
    cursor: pointer;
}

.about-experience-wrapper {
    margin-top: 2rem;
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(35vw, 1fr));
    /* Responsive grid with minimum column width of 300px */
    grid-gap: 1rem;
    /* Gap between grid items */
    justify-content: center;
    /* Center the grid items horizontally */
    align-items: stretch;
    /* Stretch grid items to match the height */
}

.about-experience-grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.3s ease-in-out;
    transform: 0.3s;
}

.about-experience {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    height: clamp(5rem, 20vh, 9rem);
    width: 35vw;
    border-radius: 0.5em;
    background-color: rgb(40, 40, 40);
    color: rgb(240, 240, 240);
    /* text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; */
    font-family: Lucida Sans Typewriter, Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
}

.about-experience-image-frame {
    height: clamp(5rem, 20vh, 9rem);
    width: 10vw;
    /* background-color:rgb(40, 40, 40); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-experience-image {
    height: 5rem;
    border-radius: 0.5rem;
}

.about-experience-title-frame {
    height: clamp(5rem, 20vh, 9rem);
    width: 30vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-experience-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.about-experience-role {
    font-size: 1rem;
    /* font-weight: bold; */
}
