@font-face {
    font-family: headingFont;
    src: url();
}
body {
    background-color: rgb(20, 20, 20);
    margin: 0px;
}

body::-webkit-scrollbar {
    display: none;
}

.swapY {
    transform: scaleY(-1);
    position: relative;
    z-index: 2;
}

/* MENU */
.nav {
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.intro {
    margin-top: 1rem;
    margin-left: 3rem;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    height: clamp(3rem, 8vh, 6rem);
    width: clamp(10rem, 40vw, 40rem);
    border-radius: 0.5em;
    background-color: rgb(40, 40, 40);
    color: rgb(240, 240, 240);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lucida Sans Typewriter, Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
}

.name {
    color: #7848c4;
}

.menu-links {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: 2rem;
    display: flex;
    flex-direction: row;
    height: clamp(3rem, 8vh, 6rem);
    width: clamp(5rem, 10vw, 10rem);
    border-radius: 0.5em;
    background-color: rgb(40, 40, 40);
    justify-content: center;
    align-items: center;
}

.github-icon {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: rgb(220, 220, 220);
    margin-left: 0.5em;
    margin-right: 1em;
}

.linkedin-icon {
    height: 1.75rem;
    width: 1.75rem;
    padding: 0.25rem;
    border-radius: 10%;
    background-color: rgb(40, 40, 40);
    margin-left: 1em;
    margin-right: 0.5em;
}

.menu {
    height: 90vh;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
}

html {
    scroll-behavior: smooth;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: min-content;
    transform: scaleX(-1);
    height: clamp(3rem, 6vw, 8rem);
    margin-top: 5%;
    margin-bottom: 2.5%;
}

/* Contents Bar */
.contents-bar {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

#bar {
    width: 1em;
    height: 80vh;
    background-color: #3a2963;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bar-wrapper {
    display: flex;
    min-width: 18em;
    align-items: center;
    justify-content: center;
}

.bar-peg {
    width: 9em;
    height: 0.75em;
    background-color: #916fff;
    transition: width 0.4s;
    align-items: center;
    justify-content: center;
    /* margin-right: 3em; */
}

.bar-projects {
    width: 12em;
    height: 0.75em;
    background-color: #4b27a1;
    transition: width 0.3s;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: clamp(9rem, 22vw, 24rem);
}

.bar-contact {
    width: 12em;
    height: 0.75em;
    background-color: #4b27a1;
    transition: width 0.5s;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: clamp(6rem, 14vw, 16rem);
}

/* Table of Contents */

.menu-items-wrapper {
    width: 70vmax;
    display: flex;
    justify-content: center;
}

#menu-items {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    height: fit-content;
    /* background-color: rgb(30,30,30); */
    border-radius: 0.5em;
    /* height: 23vmax;
  width: 50vmax; */
    width: 100%;
    /* justify-content: center; */
    /* align-items: center; */
}

.menu-item {
    color: rgb(220, 220, 220);
    font-size: 8vw;
    font-family: Geneva, Tahoma, sans-serif;
    font-weight: lighter;
    text-decoration: none;
    display: block;
    transform: scaleX(-1);
    position: relative;
    transition: font-size 0.4s, color 0.2s;
    z-index: 2;
}

.menu-item-2 {
    color: rgb(240, 240, 240);
    font-size: 8vw;
    font-family: Geneva, Tahoma, sans-serif;
    font-weight: lighter;
    text-decoration: none;
    display: block;
    transform: scaleX(-1);
    position: relative;
    transition: font-size 0.4s, color 0.2s;
}

/* Logos */
.company1-logo {
    height: 40%;
    width: auto;
    position: absolute;
    top: 30%;
    left: 0%;
    scale: 0;
    /* transform: rotate(0deg); */
    /* transform: scaleX(-1); */
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.company2-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 85%;
    top: 30%;
    scale: 0;
    /* transform: rotate(0deg); */
    /* transform: scaleX(-1); */
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.company3-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 60%;
    top: 30%;
    scale: 0;
    transform: rotate(-45deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.company4-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 25%;
    top: 30%;
    scale: 0;
    transform: rotate(45deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.js-logo {
    height: 40%;
    width: auto;
    position: absolute;
    left: 85%;
    top: 30%;
    scale: 0;

    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.python-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 30%;
    top: 30%;
    scale: 0;
    transform: rotate(45deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.react-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 55%;
    top: 30%;
    scale: 0;
    transform: rotate(-45deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.html-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 0%;
    top: 30%;
    scale: 0;
    transform: rotate(0deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.node-logo {
    height: 40%;
    width: auto;
    position: absolute;
    left: 85%;
    top: 30%;
    scale: 0;
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.sql-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 55%;
    top: 30%;
    scale: 0;
    transform: rotate(45deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.java-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 30%;
    top: 30%;
    scale: 0;
    transform: rotate(-45deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.css-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 0%;
    top: 30%;
    scale: 0;
    transform: rotate(0deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.linkedin-logo {
    height: 40%;
    width: auto;
    position: absolute;
    left: 0%;
    top: 30%;
    scale: 0;
    /* transform: scaleX(-1) scaleY(-1); */
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.coffee-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 65%;
    top: 30%;
    scale: 0;
    transform: rotate(45deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.teams-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 25%;
    top: 30%;
    scale: 0;
    transform: rotate(-45deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

.gmail-logo {
    height: 40%;
    width: auto;
    position: absolute;
    /* bottom: 100%; */
    left: 89%;
    top: 30%;
    scale: 0;
    transform: rotate(0deg);
    transition: scale 0.4s, transform 0.4s, top 0.4s, left 0.4s;
    border-radius: 5%;
}

/* Menu Effects  */
#menu-items:hover > .row > .menu-item {
    color: rgb(70, 70, 70);
}

#menu-items:hover > .row > .bar-wrapper > .bar-peg {
    width: 6em;
}

#menu-items:hover > .row > .menu-item:hover ~ .bar-wrapper > .bar-peg {
    width: 8em;
}

#menu-items:hover > .row > .menu-item:hover {
    color: rgb(240, 240, 240);
    font-size: 8.5vw;
}

/* For contact and projects */
#menu-items:hover > .swapY > .row > .swapY > .menu-item-2 {
    color: rgb(70, 70, 70);
}

#menu-items:hover > .swapY > .row > .bar-wrapper > .bar-peg {
    width: 6em;
}

#menu-items:hover > .swapY > .row > .swapY:hover ~ .bar-wrapper > .bar-peg {
    width: 9em;
}

#menu-items:hover > .swapY > .row > .swapY > .menu-item-2:hover {
    color: rgb(240, 240, 240);
    font-size: 8.5vw;
}

/* Logo Transitions on Menu */

#contact {
    height: 100vh;
}

.contact-blurb {
    margin-left: 3rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    height: clamp(3rem, 8vh, 6rem);
    width: 40%;
    border-radius: 0.5em;
    background-color: rgb(40, 40, 40);
    color: rgb(240, 240, 240);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lucida Sans Typewriter, Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
}

.contact-your-info-wrap {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
}

.contact-your-info {
    border: none;
    margin-left: 3rem;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    height: 25%;
    width: 40%;
    border-radius: 0.5em;
    background-color: rgb(40, 40, 40);
    color: rgb(240, 240, 240);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-family: Lucida Sans Typewriter, Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
    padding: 1.5rem;
    outline: none;
}

.contact-message {
    border: none;
    margin-left: 3rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    height: 40vh;
    width: 90vw;
    border-radius: 0.5em;
    background-color: rgb(40, 40, 40);
    color: rgb(240, 240, 240);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lucida Sans Typewriter, Lucida Console, monaco, Bitstream Vera Sans Mono, monospace;
    text-align: left;
    vertical-align: top;
    padding: 2rem;
    overflow: hidden;
}
textarea {
    outline: none;
    vertical-align: top;
    resize: none;
}
.contact-button-container {
    height: 15vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-button {
    border: none;
    margin-left: clamp(0.5rem, 1rem, 3rem);
    margin-right: clamp(0.5rem, 1rem, 3rem);
    height: clamp(3rem, 4vh, 6rem);
    width: clamp(10rem, 30vw, 20rem);
    text-decoration: none;
    color: rgb(220, 220, 220);
    /* border: solid #916FFF; */
    background-color: #7848c4;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bolder;
    font-size: 1.5em;
    border-radius: 0.3em;
    transition: background-color 0.2s;
}

.contact-button:hover {
    background-color: #916fff;
    cursor: pointer;
}
